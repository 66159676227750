header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    height: 80px;
    background-color: var(--mainColor);
    color: var(--textColor);
}

nav a{
    text-decoration: none;
    color: var(--textColor);
    margin: 0 2rem;
}

nav a:hover{
    color: var(--secondaryColor);

}

header .nav-btn{
    padding: 5px;
    background: transparent;
    cursor: pointer;
    border:none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

@media only screen and (max-width:768px)
 {
    header  .nav-btn{
        visibility: visible;
        opacity: 1;
    }
    header nav{
        position: absolute;
        right: 0;
        top: 0;
        height: 80%;
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 1;
    }
    header .responsive_nav{
        transform: none;
    }
    nav .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
        

    }
    
}