.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    border-radius: 10px;
    height: 71vh;
    background:#E0D9D6;
   
}
h3{
    color: var(--mainColor);
    font-weight: bold
}

.im1-wrapper{
display: flex;
gap: 0.2rem;
align-items: center;
justify-content: center;
}
.students{
    width: 200px;
    height: 200px;
    border-radius: 10px;
}
.quran{
    width: 200px;
    height: 200px;
    border-radius: 10px;
}

.calling{
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
 
    
}
.calling img{
    width: 200px;
    height: 200px;
    border-radius: 10px;
}
.calling p{
    color: var(--mainColor);
    font-size: 1.2rem;
    font-weight: bold;
}
@media(max-width:768px){

    .wrapper{
        height: 80vh;
        width: 100%;
    }
    .im1-wrapper,.calling{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .calling p{
        font-size: 1rem;
    }

    .students,.quran,.calling img{
        width: 130px;
        height: 130px;
        border-radius: 10px;
    }

}
