@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;600&display=swap'); 


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family:'Titillium Web', sans-serif;;
}
:root{
    --mainColor:#29335C;
    --mainColorLight:#445496;
    --secondaryColor:#DB2B39;
    --textColor:#eee;

}

