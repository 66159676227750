.r-wrapp
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    justify-content: flex-start;
    border-radius: 10px;
    height: 71vh;
    background:#E0D9D6;
}

P{
    color: var(--mainColor);
    font-size: 1rem;
    font-weight: bold;
}
.couses{
  
    color: black;
}
@media(max-width:768px){

    .r-wrapp{
        width: 100%;

    }
}