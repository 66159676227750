.footer{
    width: 100%;
    height: 54vh;
    position: relative;
    background: var(--mainColor);
    

    
    
}
.fo-desc{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

h1,h2{
    color: white;
}
.footer-wrapper{
    display: flex;
    justify-content: space-around;    
}
.follow p{
    color: white;

}
.fo-desc p{
    color: white;
}
.con-way p{
    color: white;
    font-weight: bold;

}

textarea{
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

@media (max-width:768px){
    .footer{
        height: 100vh;
    }

    .footer-wrapper{
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
    }
    .f-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    
    

}