.card-wrapper{
    display: flex;
    gap: 1.2rem;
    justify-content: space-between;
    align-items: center;
}

@media   (max-width:768px)
{

    .card-wrapper{
        display: flex;
        position: relative;
        margin-top: 10px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}