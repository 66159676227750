.rapper{
   
    width: 100%;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    
   
}
.gradient{
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
}
img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
}
 .text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 4rem;
    padding-bottom: 20px;
  
   
}
.text p{
    margin-top: 2rem;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    
}
.text .quran1{
    color: white;
    font-size: 1.5rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}
@media (max-width:768px){
    .text p{
        top: -10px;
        font-size: 1rem;
        margin-top: 0;
      
    }
    .rapper{
        height: 100%;
    }
.quran1{
    font-size: 0.5rem;
    width: 100%;
}
.rapper{
    margin-bottom: 20px;

}
}